@import "D:/0_Nebo/fudline/src/scss/base/_01_normalize.scss";
@import "D:/0_Nebo/fudline/src/scss/base/_mixins.scss";

@import "D:/0_Nebo/fudline/src/scss/global/_fonts.scss";
@import "D:/0_Nebo/fudline/src/scss/global/_global.scss";
@import "D:/0_Nebo/fudline/src/scss/global/_svg.scss";

@import "D:/0_Nebo/fudline/src/scss/_local-fonts.scss";
@import "D:/0_Nebo/fudline/src/scss/base/_01_normalize.scss";
@import "D:/0_Nebo/fudline/src/scss/base/_mixins.scss";
@import "D:/0_Nebo/fudline/src/scss/global/_fonts.scss";
@import "D:/0_Nebo/fudline/src/scss/global/_global.scss";
@import "D:/0_Nebo/fudline/src/scss/global/_svg.scss";



.container{
    margin-right: 75px;
    margin-left: 75px;
}
.header{
    padding-top: 5px;
    padding-bottom: 5px;
    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    nav{
        ul{
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            gap: 30px;
            list-style-type: none;
            a{
                color: #1D1D1B;
                font-family: 'Roboto';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                    color: #BD1823;
                }
            }
        }
    }
    .logo{
        width: 110px;
        height: 119.999px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 25px;
    }
    .socials{
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 25px;
        li{
            &:hover{
                a{
                    svg{
                        path{
                            fill: red;
                        transition: 1s all;
                        }
                    }
                }
            }
            a{
                text-decoration: none;
            }
        }
    }
    .red-btn-width-arrow{
        display: flex;
        flex-direction: row;
        gap: 22px;
        align-items: center;
        text-decoration: none;
        background: #BD1823;
        color: #FFF;
        font-family: 'Roboto';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-top: 9px;
        padding-bottom: 11px;
        padding-left: 26px;
        padding-right: 10px;
        border-radius: 100px;
        border:1px solid transparent;
        span{
            display: contents;
        }
        &:hover{
            // border:1px solid #BD1823;
            background: #8C141C;
            transition: 1s;
            // color: #BD1823;
        }
    }
}
.main{
    .container{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
    .column{
        &:first-child{
            max-width: 520px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 134px;
        }
        &:last-child{
            max-width: 720px;
            margin-right: -75px;
            display: flex;
            .banner{
                border-radius: 250px 0px 0px 0px;
            }
        }
    }
    &__title{
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: 60px;
        letter-spacing: -0.5px;
    }
    .bottom{
        display: flex;
        flex-direction: column;
        gap: 20.5px;
    }
    &__adress{
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    &__phones{
        display: flex;
        flex-direction: row;
        gap: 25px;
        margin-bottom: 27px;
        a{
            text-decoration: none;
            color: #1D1D1B;
            font-family: 'Roboto';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
                margin-right: 7px;
            }
            &:hover{
                text-decoration: underline;
                color: #BD1823;
                span{
                    svg{
                        path{
                            fill: #BD1823;
                            stroke: #BD1823;
                        }
                    }
                }
            }
        }
    }
}
.activities{
    position: relative;
    &__title{
        margin-top: 54px;
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: -0.4px;
        max-width: 410px;
    }
    &__swiper{
        width: 100%;
        height: 1000px;
        margin-right: -75px;
        .swiper-slide{
            height: 250px;
            width: 100%;
            background: white;
            padding-left: 61px;
            padding-top: 51px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            overflow: hidden;
            border-bottom: 1px solid #E9E9E9;
            padding-left: 20px;
            img{
                margin-top: 16px;
                margin-right: -4px;
            }
            .title{
                color: #1D1D1B;
                font-family: 'Roboto';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: -0.25px;
                margin-bottom: 19px;
            }
            .description{
                color: #5A5A59;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
            .cta{
                color: #1D1D1B;
                text-align: center;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                span{
                    height: 25px;
                    width: 25px;
                    border-radius: 25px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                &:hover{
                    span{
                        background: red;
                        svg{
                            path{
                                stroke: white;
                            }
                        }
                    }
                }
            }
            .column{
                &:last-child{
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }
        }
    }
    .container{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        margin-right: 0;
    }
    .column{
        width: 50%;
    }
    .background-img{
        position: absolute;
        top: 64px;
        left: 50%;
        transform: translate(-50%,0);
        z-index: -1;
    }
}
.activities .swiper-slide{
    &:hover{
        cursor: pointer;
        img{
            opacity: 0.3;
            transition: 1s all;
        }
        span{
            background: red;
            transition: 1s all;
            svg{
                path{
                    stroke: white;
                    transition: 1s all;
                }
            }
        }
    }

}
.advantages{
    background: #F6F6F6;
    padding-bottom: 41px;
    &__title{
        padding-top: 84px;
        padding-bottom: 51px;
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: -0.4px;
        max-width: 410px;
    }
    .row{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        &:last-child{
            padding-bottom: 69px;
            border-bottom: 1px solid #E9E9E9;
        }
    }
    &__item{
        padding: 30px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 26px;
        background: #FFF;
        width: 300px;
        .img{
            background: #BD1823;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-top: 82px;
            padding-bottom: 99px;
            border-radius: 5px;
        }
        .name{
            color: #1D1D1B;
            font-family: 'Roboto';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
        }
    }
}
.parnters{
    border-bottom: 1px solid #E9E9E9;
    // padding-bottom: 53px;
    .container{
        margin-right: 0px !important;
    }
    &__swiper{
        width: 100%;
        height: 750px;
        margin-right: -75px;
        border-left: 1px solid #E9E9E9;
        .swiper-slide{
            height: 250px;
            width: 100%;
            background: white;
            padding-left: 61px;
            padding-top: 51px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            overflow: hidden;
            border-bottom: 1px solid #E9E9E9;
            &:last-child{
                border-bottom: none;
            }
            .title{
                color: #1D1D1B;
                font-family: 'Roboto';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px; /* 120% */
                letter-spacing: -0.25px;
                margin-bottom: 19px;
            }
            .description{
                color: #5A5A59;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 125% */
            }
            .cta{
                color: #1D1D1B;
                text-align: center;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                span{
                    height: 25px;
                    width: 25px;
                    border-radius: 25px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                &:hover{
                    span{
                        background: red;
                    }
                }
            }
            .column{
                &:last-child{
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }
        }
    }
}
.vacancies{
    .container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .title{
        padding-top: 84px;
        margin-bottom: 51px;
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: -0.4px;
    }
    .tabs{
        ul{
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            list-style-type: none;
            gap: 10px;
            li{
                cursor: pointer;
                span{
                    display: block;
                    // padding: 10px 15px;
                    border-radius: 200px;
                    border: 1px solid rgba(189, 24, 35, 0.40);
                    color: #1D1D1B;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 9px 29px;
                    &:hover{
                        background: #BD1823;
                        color:white;
                    }
                    &.active{
                        background: #BD1823;
                        color:white;
                    }
                }
            }
        }
    }
    &__wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 40px;
        gap: 2%;
        row-gap: 30px;
    }
    &__item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 32%;
        padding: 40px;
        background: #F6F6F6;
        // box-sizing: content-box;
        .tab{
            color: #1D1D1B;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            opacity: 0.3;
        }
        .title{
            padding-top: 12px;
            color: #1D1D1B;
            font-family: 'Roboto';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px; /* 125% */
            margin-bottom: 174px;
        }
        div{
            &:last-child{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                color: #1D1D1B;
                text-align: center;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                cursor: pointer;
                span{
                    display: contents;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .btn-red-with-arrow-down{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 200px;
        background: #BD1823;
        color: #FFF;
        gap: 15px;
        font-family: 'Roboto';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 90px;
        cursor: pointer;
        border:1px solid #BD1823;
        margin-top: 27px;
        padding: 7px 14px;
        &:hover{
            background: #FFF;
            color: #BD1823;
            border:1px solid #BD1823;
        }
    }
}
.contacts{
    background: #F6F6F6;
    .container{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap:60px;
    }
    .column{
        &:first-child{
            margin-left: -75px;
            width: 805px;
            display: flex;
            img{
                width: 100%;
            }
        }
    }
    .title{
        padding-top: 54px;
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: -0.4px;
        margin-bottom: 237px;
    }
    &__item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 46px;
        span{
            &:first-child{
                color: #BBB;
                font-family: 'Roboto';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                margin-bottom: 15px;
            }
            &:nth-child(2){
                color: #1D1D1B;
                font-family: 'Roboto';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 125% */
                margin-bottom: 5px;
            }
        }
        a{
            color: #1D1D1B;
            font-family: 'Roboto';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            text-decoration: none;
            margin-bottom: 11px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:5px;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .row{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: -7px;
        gap: 88px;
        .contacts__item{
            margin-bottom: 0;
        }
    }
    .socials{
        span{
            color: #BBB;
            font-family: 'Roboto';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }
        ul{
            margin: 0;
            padding: 0;
            padding-top: 15px;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            gap: 10px;
            li{
                a{
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background: white;
                    &:hover{
                        path{
                            fill:#BD1823;
                        }
                    }
                }
            }
        }
    }
}
.form{
    .container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: normal;
    }
    .column{
        &:first-child{
            background: #F6F6F6;
            margin-left: -75px;
            width: 805px;
        }
        &:last-child{
            width: 720px;
        }
    }
    .title{
        padding-top: 59px;
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: -0.4px;
        margin-left: 75px;
    }
    
}
form{
    padding-top: 60px;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding-bottom: 55px;
}
.form-group{
    display: flex;
    flex-direction: column;
    label{
        color: #1D1D1B;
        font-family: 'Roboto';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        padding-bottom: 12px;
    }
    input{
        color: #BBB;
        font-family: 'Roboto';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: none;
        border-bottom: 1px solid #E9E9E9;
        padding-bottom: 10px;
        &::placeholder{
            color: #BBB;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
    span{
        color: #BBB;
        font-family: 'Roboto';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        a{
            color: #1D1D1B;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
button{
    border: none;
    outline: none;
    background: none;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 9px;
    padding-bottom: 11px;
    padding-left: 20px;
    padding-right: 10px;
    border-radius: 200px;
    background: #BD1823;
    color: #FFF;
    border: 1px solid #BD1823;
    gap: 15px;
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-top: 20px;
    &:hover{
        background: #FFF;
        color: #BD1823;
        border: 1px solid #BD1823;
    }
}
footer{
    background: #1D1D1B;
    .container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10%;
    }
    a{
        &:hover{
            text-decoration: underline !important;
        }
    }
    .column{
        &:first-child{
            width: 190px;
            background: #BD1823;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .logo{
                width: 90px;
                height: 98px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &:last-child{
            display: flex;
            flex-direction: column;
            flex: 1;
            nav{
                padding-top: 85px;
                padding-bottom: 58px;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    list-style-type: none;
                    li{
                        a{
                            color: #FFF;
                            font-family: 'Roboto';
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            text-decoration: none;
                        }
                        &:last-child{
                            a{
                                color: #5A5A59;
                            }
                        }
                    }
                }
            }
            p{
                margin: 0;
                padding: 0;
                color: #5A5A59;
                font-family: 'Roboto';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px; /* 107.143% */
                padding-bottom: 12px;
            }
        }
    }
}
.modal{
    padding-top: 10vh;
}
.modal-content-parent{
    width: 76%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.modal-content{
    height: 80vh;
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-between;
    position: relative;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
    .background-img{
        position: absolute;
        width: 370.054px;
        height: 366.007px;
        top: 98px;
        left: 45%;
        transform: translate(-50%,0);
        img{
            width: 100%;
            height: 100%;
        }
    }
    .column{
        &:nth-child(2){
            z-index: 1;
            background: #F6F6F6;
            width: 55%;
            padding: 55px;
            row-gap: 20px;
            column-gap: 30px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: auto;
        }
        .item{
            width: calc(50% - 30px);
            height: 180px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            .link{
                padding-top: 28px;
                a{
                    color: #1D1D1B;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap:5px;
                }
            }
        }
    }
    .title{
        color: #1D1D1B; 
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: -0.4px;
    }
    .column{
        &:nth-child(2){
            &.another-struct{
                justify-content: center;
                .item{
                    width: calc(89% - 30px);
                    height: 255px;
                    &.big{
                        height: auto;
                        padding-top: 50px;
                        padding-bottom: 45px;
                        .top{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            .link{
                                padding-top: 10px;
                            }
                        }   
                        .bottom{
                            padding-top: 55px;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            margin-right: 30px;
                            margin-left: 30px;
                            gap: 40px;
                            display: grid;
                            grid-template-columns: 30% 30% 30%;
                        }
                    }
                }
            }
        }
        &:nth-child(1){
            padding-top: 56px;
            padding-left: 55px;
            padding-bottom: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .top{
                .sub-title{
                    color: #BBB;
                    font-family: 'Roboto';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    padding-bottom: 8px;
                }
                .title{
                    padding: 0;
                }
            }
            .bottom{
                display: flex;
                flex-direction: column;
                span{
                    color: #1D1D1B;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    opacity: 0.3;
                    padding-bottom: 15px;
                }
                a{
                    color: #1D1D1B;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    text-decoration: none;
                    padding-bottom: 5px;
                }
            }
        }
    }
    .vacancies-modal{
        background: #fff !important;
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: nowrap !important;
        p{
            padding: 0;
            margin: 0;
            color: #1D1D1B;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            opacity: 0.3;
        }
        ul{
            padding: 0;
            margin: 0;
            margin-left: 19px;
            li{
                color: #1D1D1B;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 125% */
            }
        }
        form{
            padding: 0;
        }
    }
}
.modal-content .column .item .img{
    max-width: 100%;
    img{
        width: 100%;
    }
}
main{
    overflow: hidden;
}
.modal-content .column:nth-child(1){
    z-index: 1;
}
#mobile{
    z-index: 1;
    width: auto;
    height: auto;
    &::after{
        display: block;
    }
    &::before{
        display: block;
    }
}
@media (min-width:1680px) and ((max-width:2560px)){
    .container{
        margin-left: auto;
        margin-right: auto !important;
        width: 80%;
    }
    .main .column{
        width: 50% !important;
        max-width: 50% !important;
    }
    .main .container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .main .column:last-child .banner{
        width: 100%;
        object-fit: cover;
    }
}
@media (max-width:1400px) {
    .form .container .column,
    .contacts .container .column{
        width: 50%;
    }
    .contacts .title{
        margin-bottom: 100px;
    }
    .advantages .row:last-child{
        flex-wrap: wrap;
        row-gap: 20px;
        align-items: normal;
    }
    .advantages__item{
        width: 48%;
    }
    .modal-content .column:nth-child(2){
        width: 65%;
    }
    .modal-content .column:nth-child(1){
        width: 35%;
    }
}
@media (max-width:1280px){
    .main__title{
        font-size: 35px;
        line-height: 140%;
    }
    .contacts .container{
        align-items: normal;
    }
    footer .column:last-child nav ul{
        flex-wrap: wrap;
        gap: 15px;
    }
    .main .column:last-child{
        width: 50%;
    }
    .main .column:last-child .banner{
        width: 100%;
    }
    .main .container{
        align-items: normal;
    }
    .main .column:first-child{
        padding-top: 50px;
        gap: 0;
        justify-content: space-between;
    }
    .contacts .row{
        flex-direction: column-reverse;
        gap: 15px;
    }
    .modal-content .title{
        font-size: 25px;
        line-height: 120%;
    }
    .header .logo{
        width: 90px !important;
        height: auto !important;
    }
    .header nav ul{
        gap: 15px;
    }
    .header .red-btn-width-arrow{
        padding: 6px 8px 6px 14px;
        font-size: 14px;
        gap: 15px;
    }
}
@media (max-width:1024px){
    .main__title{
        font-size: 22px;
        padding-bottom: 25px;
    }
    .main__phones{
        flex-direction: column;
    }
    .activities .container{
        flex-direction: column;
        margin-right: 75px !important;
    }
    .activities .column{
        width: 100%;
    }
    .activities__title{
        width: 100%;
        max-width: 100%;
        padding-bottom: 25px;
    }
    .main .column:first-child{
        width: 60%;
    }
    .main .column:last-child{
        width: 40%;
    }
    .vacancies__item{
        width: 49%;
    }
    .contacts .column:first-child img{
        object-fit: cover;
    }
    .activities .background-img{
        display: none;
    }
    .modal-content .column .item{
        width: 100%;
    }
    #mobile{
        z-index: 1;
        ul{
            display: none !important;
        }
        cursor: pointer !important;
        position: relative !important;
        width: 30px !important;
        height: 5px !important;
        background: #BD1823 !important;
        &::after{
            display: block;
            content: '';
            position: relative;
            top: -15px;
            width: 30px;
            height: 5px;
            background: #BD1823;
        }
        &::before{
            display: block;
            content: '';
            position: relative;
            bottom: -10px;
            width: 30px;
            height: 5px;
            background: #BD1823;
        }
        &.active{
            ul{
                display: block !important;
                margin-left: 60px !important;
                margin-top: -12px !important;
            }
            background: transparent !important;
            &::after{
                position: absolute;
                top: 0;
                bottom: auto;
                transform: rotate(-45deg);
            }
            &::before{
                position: absolute;
                top: 0;
                bottom: auto;
                transform: rotate(45deg);
            }
        }
    }
    .main .container{
        position: relative;
    }
    .main .column:last-child .banner{
        border-radius: 0;
        position: absolute;
        left: -75px;
        top: 0;
        width: calc(100% + 150px);
        transform: translate(0,-230px);
        opacity: 0.3;
        z-index: -1;
    }
    .header.active{
        .logo{
            opacity: 0;
        }
        .left{
            opacity: 0 !important;
        }
    }
    .main__title,.main__adress,.main__phones{
        text-shadow: 3px 3px 2px #E9E9E9;
    }
    .main .column:first-child{
        width: 100%;
    }
    .main .column:last-child{
        width: 0;
    }
    .main__phones{
        gap: 15px !important;
        margin-bottom: 15px;
    }
    .main .bottom{
        gap: 15px !important;
    }
    .ticker{
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
    .main__title{
        padding-bottom: 15px;
    }
    .main .column:first-child{
        padding-top: 15px;
    }
    .ticker .item{
        font-size: 20px;
    }
    .ticker .item::before{
        width: 37px !important;
        height: 40px !important;
        background-size: contain;
    }
    .activities__title{
        margin-top: 15px;
    }
    .swiper.activities__swiper{
        flex-direction: column;
        height: auto;
    }
    .activities__swiper .swiper-wrapper{
        flex-direction: column;
    }
    .activities__swiper .swiper-slide{
        height: auto;
        padding-left: 0;
    }
    .activities__title{
        padding-bottom: 15px;
    }
}
@media (max-width:768px){
    .modal-content .column:nth-child(2){
        background: #f6f6f6 !important;
    }
    .modal-content form input{
        background: transparent;
    }
    .modal-content .column:nth-child(1){
        width: 100%;
        background: #f6f6f6;
    }
    .modal-content .column:nth-child(2){
        width: 100%;
        padding-top: 0;
    }
    footer .column:last-child .row:last-child{
        flex-direction: column !important;
    }
    .form .container{
        flex-direction: column;
    }
    .form .column{
        width: 100% !important;
    }
    .form form{
        padding-left: 0;
    }
    .form .column:first-child{
        background: transparent;
    }
    .container,.activities .container{
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .main .column:last-child .banner{
        object-fit: cover;
    }
    .parnters__swiper .swiper-slide .title{
        margin-bottom: 5px;
    }
    .parnters__swiper .swiper-slide .description{
        font-size: 12px;
    }
    .vacancies .tabs ul{
        flex-wrap: wrap;
    }
    .vacancies__item{
        width: 100%;
    }
    .vacancies__item .title{
        margin-bottom: 50px;
    }
    .modal-content{
        flex-direction: column;
    }
    .modal-content .background-img{
        display: none;
        
    }
}
@media (max-width:600px){
    .header .logo{
        width: 55px !important;
    }
    .modal-content-parent{
        width: 93%;
    }
    .header .socials{
        display: none;
    }
    .activities .container{
        margin-right: auto !important;
    }
    .advantages__item{
        width: 100%;
    }
    .contacts .container .column:first-child{
        display: none;
    }
    .contacts .container .column:last-child{
        width: 100%;
    }
    footer .column:last-child nav ul{
        flex-direction: column;
    }
    .main .column:first-child{
        width: 100%;
    }
    .ticker{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
    .ticker .item{
        font-size: 14px !important;
    }
    .main .column:last-child .banner{
        transform: translate(0px,-96px);
    }
    .header .red-btn-width-arrow{
        font-size: 14px;
        gap: 5px;
    }
    .main__title{
        font-size: 18px;
    }
    .main__adress{
        font-size: 16px;
    }
    .activities__title{
        font-size: 24px;
        line-height: 140%;
    }
    .activities__swiper .swiper-slide{
        padding-left: 0;
        position: relative;
    }
    .activities__swiper .swiper-slide .column:last-child{
        position: absolute;
        bottom: -130px;
    }
    .activities__swiper .swiper-slide img{
        height: 230px;
        width: auto;
    }
    .parnters__swiper .swiper-slide{
        padding-left: 0;
        position: relative;
    }
    .parnters__swiper .swiper-slide .column:last-child{
        position: absolute;
        bottom: -50px;
    }
    .parnters__swiper{
        border-left: none !important;
    }
    .parnters__swiper .swiper-slide .description{
        background: #ffffffbd;
        z-index: 1;
        position: relative;
    }
    .vacancies .title{
        font-size: 30px;
    }
    .vacancies__item .title{
        font-size: 20px !important;
    }
    footer .column:last-child nav ul li a{
        font-size: 16px
    }
}
